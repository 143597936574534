@import 'styles/theme';

.Input {
  position: relative;
  margin-top: theme($spacings, 35);

  @include from('md') {
    margin-top: theme($spacings, 45);
  }

  &.password {
    .input {
      padding-right: theme($spacings, 35);
    }
  }

  &.hidden {
    display: none;
  }

  &.inline {
    .input {
      padding-right: 0;
      padding-bottom: 0;
      border-bottom-color: theme($colors, 'black');
    }
  }

  .label {
    display: none;
    position: absolute;
    top: calc(#{theme($spacings, 5)} * -1);
    left: 0;
    line-height: 1;
    transform: translateY(-100%);
  }

  .input,
  .textarea {
    &.withError {
      color: theme($colors, 'red');

      &::placeholder {
        color: theme($colors, 'red');
      }
    }
  }

  .phone.withError {
    input {
      color: theme($colors, 'red');
      border-bottom: solid 1px theme($colors, 'red');
    }
  }

  .input {
    width: 100%;
    border: none;
    border-bottom: solid 1px;
    border-radius: 0;
    box-sizing: border-box;
    padding: 0 theme($spacings, 25) theme($spacings, 9) 0;
    transition: border 0.2s ease;
    border-bottom: solid 1px theme($colors, 'gray-93');
    background-color: transparent;

    &:focus {
      border-bottom: solid 1px theme($colors, 'black');
    }

    &.withError {
      border-bottom: solid 1px theme($colors, 'red');
    }
  }

  .input[type='text'],
  .input[type='email'],
  .input[type='password'],
  .input[type='tel'] {
    &:not(:placeholder-shown) + .label {
      display: block;
    }
  }

  .input[type='password'] {
    font-family: caption;
  }

  .Errors {
    color: theme($colors, 'red');
    margin-top: theme($spacings, 10);
    text-align: right;
    position: absolute;
    right: 0;

    * {
      letter-spacing: normal;
      line-height: 1;
    }
  }

  .textarea {
    outline: none;
    transition: border 0.2s ease;
    border: solid 1px theme($colors, 'gray-93');
    padding: theme($spacings, 15);
    box-sizing: border-box;
    resize: none;

    &:focus {
      border-color: theme($colors, 'black');
    }

    &::placeholder {
      color: theme($colors, 'gray-47');
    }

    &.withError {
      border: solid 1px theme($colors, 'red');
    }
  }
}
